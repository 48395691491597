import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnvState } from 'models/envModels';
import { RootState } from 'store/Store';

const initialState: EnvState = { currentEnvironment: 'qa' };

export const envSlice = createSlice({
  name: 'env',
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setCurrentEnvironment: (state, action: PayloadAction<string>) => {
      state.currentEnvironment = action.payload;
    },
  },
  /* eslint-enable no-param-reassign */
});

export const selectCurrentEnv = (state: RootState) => state.env.currentEnvironment;

export const envReducer = envSlice.reducer;
export const { setCurrentEnvironment } = envSlice.actions;
