import React from 'react';
import HamburgerButton from '../../UI/HamburgerButton';
import { MenuAuthWrapper } from '../MenuAuthWrapper';

import {
  ActionMenu,
  Container,
  Content,
  Menu,
  MobileMenu,
  PrimaryArea,
  SideArea,
  StyledH1,
  TopBorder,
} from './styled';

// Render Staccato
const renderStaccato = (path: string) => (
  <svg width="40" height="56">
    <path d={path} fill="currentColor" fillRule="nonzero" />
  </svg>
);

type Props = {
  setMobileMenuOpen: (isOpen: boolean) => void;
  mobileMenuOpen: boolean;
  sideArea?: JSX.Element;
  searchParams: URLSearchParams;
  targetedEnvironment?: JSX.Element;
};

export const NavBar: React.FC<Props> = ({
  mobileMenuOpen,
  setMobileMenuOpen,
  sideArea = null,
  searchParams,
  targetedEnvironment,
}) => {
  return (
    <Container>
      <TopBorder />
      <Content>
        <MobileMenu>
          <SideArea>
            <HamburgerButton isOpen={mobileMenuOpen} setIsOpen={setMobileMenuOpen} />
          </SideArea>
          {renderStaccato(
            'm15.43 11.538 9.41 32.924A15.905 15.905 0 0 0 40.14 56V0h-40c7.1 0 13.34 4.709 15.29 11.538Z',
          )}
        </MobileMenu>
        <PrimaryArea>
          <StyledH1>AMC Operation Tools</StyledH1>
          <Menu>
            <MenuAuthWrapper searchParams={searchParams} />
            {targetedEnvironment}
          </Menu>
        </PrimaryArea>
        <ActionMenu>
          {renderStaccato(
            'm24.85 11.538-9.41 32.924A15.905 15.905 0 0 1 .14 56V0h40c-7.1 0-13.34 4.709-15.29 11.538Z',
          )}
          <SideArea>{sideArea}</SideArea>
        </ActionMenu>
      </Content>
    </Container>
  );
};
