import { MouseEvent } from 'react';
import { Button, Colors } from 'components/UI/Button/Button';
import { StyledButtonContainer, StyledModal, StyledModalBackground } from './styled';

interface Props {
  twinExistsModalOnOkClick: (e: MouseEvent<HTMLButtonElement>) => void;
  onCancelClickHandler: (e: MouseEvent<HTMLButtonElement>) => void;
  currentEnvironment: string;
}

export const RemoteDebuggingTwinExistsModal = ({
  twinExistsModalOnOkClick,
  onCancelClickHandler,
  currentEnvironment,
}: Props) => {
  return (
    <StyledModalBackground>
      <StyledModal>
        <h2>Attention!</h2>
        <p>
          We didn&apos;t find a Twin for your mower in this enviornment.
          <br />
          Are you sure that <strong>{currentEnvironment}</strong> is the right environment?
        </p>
        <StyledButtonContainer>
          <Button onClick={twinExistsModalOnOkClick} widthPercent="35%">
            Yes
          </Button>
          <Button onClick={onCancelClickHandler} color={Colors.Secondary} widthPercent="35%">
            Cancel
          </Button>
        </StyledButtonContainer>
      </StyledModal>
    </StyledModalBackground>
  );
};
