import { useAppSelector } from 'hooks/hooks';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import {
  RemoteDebugSessionInput,
  RemoteDebugSessionRequest,
  MowerEnvironmentSession,
} from 'models/remoteDebugSessionModel';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  useGetAllRemoteDebugSessionsQuery,
  useLazyGetTwinExistsQuery,
  usePostRemoteDebugSessionMutation,
} from 'store/api/tools-v1';
import { useState, MouseEvent } from 'react';
import { roles } from '../../constants';
import ToolSideBar from '../../components/fragments/ToolSidebar/ToolSidebar';
import { RemoteDebuggingForm } from './RemoteDebuggingForm';
import { StyledRemoteDebuggingPresentation } from './styled';
import { RemoteDebuggingLiveWarningModal } from './RemoteDebuggingLiveWarningModal';
import { RemoteDebugSessionsTable } from './RemoteDebugSessionsTable';
import { RemoteDebuggingTwinExistsModal } from './RemoteDebuggingTwinExistsModal';

export const RemoteDebuggingPage = () => {
  const [searchParams] = useSearchParams();
  const currentEnvironment = searchParams.get('env') ?? 'qa';
  const [liveWarningModalVisible, setLiveWarningModalVisible] = useState(false);
  const [twinExistsModalVisible, setTwinExistsModalVisible] = useState(false);
  const [waitingRequest, setWaitingRequest] = useState<RemoteDebugSessionRequest | undefined>(
    undefined,
  );

  const [fetchTwinExists, { error: twinExistsQuerryError, isFetching: twinExistsIsFetching }] =
    useLazyGetTwinExistsQuery();

  const { role, username } = useAppSelector((state) => state.auth.session);
  const location = useLocation();
  const navigate = useNavigate();
  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  const {
    data: queryData,
    error: queryError,
    isFetching: queryIsFetching,
  } = useGetAllRemoteDebugSessionsQuery(undefined);

  const [
    sendRemoteDebugSession,
    { isLoading: mutationIsLoading, isSuccess: mutationSuccess, error: mutationError },
  ] = usePostRemoteDebugSessionMutation();

  const noEnv: MowerEnvironmentSession = '';
  const onSubmit: SubmitHandler<RemoteDebugSessionInput> = (formData) => {
    const dayInMs = 1000 * 60 * 60 * 24;
    const remoteDebugSessionRequest: RemoteDebugSessionRequest = {
      serialNumber: formData.serialNumber,
      requestedBy: username,
      endingTimestamp: Date.now() + formData.endInNrOfDays * dayInMs,
      mowerEnvironmentSession: noEnv,
    };

    setWaitingRequest(remoteDebugSessionRequest);
    fetchTwinExists(formData.serialNumber)
      .unwrap()
      .then((result) => {
        if (result.twinExists) {
          if (currentEnvironment === 'live') {
            setWaitingRequest(remoteDebugSessionRequest);
            setLiveWarningModalVisible(true);
          } else {
            sendRemoteDebugSession(remoteDebugSessionRequest);
          }
        } else {
          setWaitingRequest(remoteDebugSessionRequest);
          setTwinExistsModalVisible(true);
        }
      });
  };

  const twinExistsModalOnOkClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (currentEnvironment === 'live') {
      setTwinExistsModalVisible(false);
      setLiveWarningModalVisible(true);
    } else if (waitingRequest) {
      sendRemoteDebugSession(waitingRequest);
      setTwinExistsModalVisible(false);
    }
  };

  const twinExistsModalOnCancelClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setTwinExistsModalVisible(false);
    setWaitingRequest(undefined);
  };

  const liveWarningModalOnOkClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (waitingRequest) {
      sendRemoteDebugSession(waitingRequest);
      setWaitingRequest(undefined);
    }
    setLiveWarningModalVisible(false);
  };

  const liveWarningModalOnCancelClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setLiveWarningModalVisible(false);
    setWaitingRequest(undefined);
  };

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<RemoteDebugSessionInput>({
    defaultValues: { endInNrOfDays: 60 },
  });

  const tableHeaders = ['Serial Number', 'Requested By', 'Session started at', 'Session ending at'];

  return (
    <>
      <ToolSideBar>
        <RemoteDebuggingForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={formErrors}
          placeholder="123456789-123456789"
        />
      </ToolSideBar>
      <StyledPageWrapper>
        <StyledRemoteDebuggingPresentation>
          <h1>Remote Debugging</h1>
          {queryIsFetching && !queryError && <h3>Loading stored sessions...</h3>}
          {queryError && (
            <div>
              <h2>Failed to fetch stored sessions.</h2>
            </div>
          )}
          {queryData && !queryIsFetching && (
            <>
              <h4>Current stored sessions:</h4>
              <RemoteDebugSessionsTable headers={tableHeaders} queryData={queryData} />
            </>
          )}
          {mutationIsLoading && !mutationError && !twinExistsModalVisible && (
            <h3>Sending session request...</h3>
          )}
          {mutationError && (
            <div>
              <h2>Failed to store session.</h2>
            </div>
          )}
          {mutationSuccess && !twinExistsModalVisible && !mutationIsLoading && !mutationError && (
            <h3>Session added!</h3>
          )}
          {liveWarningModalVisible && !twinExistsModalVisible && (
            <RemoteDebuggingLiveWarningModal
              onOkClickHandler={liveWarningModalOnOkClick}
              onCancelClickHandler={liveWarningModalOnCancelClick}
            />
          )}
          {twinExistsIsFetching && !twinExistsQuerryError && (
            <h3>Checking if a Twin exists in this environment...</h3>
          )}
          {twinExistsQuerryError && <h2>Failed to fetch mower twin.</h2>}

          {twinExistsModalVisible && (
            <RemoteDebuggingTwinExistsModal
              currentEnvironment={currentEnvironment}
              twinExistsModalOnOkClick={twinExistsModalOnOkClick}
              onCancelClickHandler={twinExistsModalOnCancelClick}
            />
          )}

          <hr />
          <h4>Instructions</h4>
          <p>
            This tool lets you send a request for enabling the setting &quot;RemoteDebugging&quot;
            on a mower. The requests are processed immediately, but if the mower is not online the
            requests will be retried every five minutes.
          </p>
          <p>
            As soon as the command reaches the mower and the setting is enabled, the mower will
            connect to ProxyInCloud.
          </p>
          <p>
            <b>IMPORTANT!</b> If the mower in question is owned by a customer, you <b>MUST</b> have
            permission from the customer before activating this! This is related to GDPR.
          </p>
          <p>
            Submit the mower&rsquo;s nine digit serial number and a period between 1 and 365 days
            after which the RemoteDebugging will be automatically disabled (this is to prevent
            mowers from being forgotten in an enabled state).
          </p>
          <p>
            <b>NOTE!</b> Enabling RemoteDebugging on a mower causes it to connect to ProxyInCloud,
            but to be able to do anything with it, you will need to register the mower and yourself
            through the Ownership Dashboard. To get access, contact the Robotic Tools team.
          </p>
        </StyledRemoteDebuggingPresentation>
      </StyledPageWrapper>
    </>
  );
};
