import styled from 'styled-components';
import { boxShadows } from 'styles';

export const StyledRemoteDebuggingPresentation = styled.pre`
  h2 {
    color: red;
  }
  h3 {
    color: green;
  }
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  width: 70%;
`;
export const StyledTableHead = styled.thead``;
export const StyledTableHeader = styled.th`
  border: 1px solid #3d3d3c;
  text-align: center;
  padding: 8px;
`;
export const StyledTableBody = styled.tbody``;
export const StyledTableRow = styled.tr`
  th:not(:last-child) {
    border-right: 1px solid #3d3d3c;
  }
`;
export const StyledTableData = styled.td`
  border: 1px solid #3d3d3c;
  text-align: center;
  padding: 8px;
`;

export const StyledModalBackground = styled.div`
  background-color: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledModal = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 1%;
  white-space: initial;
  text-align: center;
  font-size: 1.1em;
  box-shadow: ${boxShadows.xl};
  strong {
    color: rgb(255, 0, 0);
  }
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
